import React, { useState } from 'react'
import styled from 'styled-components'
//components
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { motion } from 'framer-motion'
import { fadeAnimation, fadeItem } from '../components/MainStyles/animations'
import { StaticImage } from 'gatsby-plugin-image'
//images
// import AboutImage from '../images/about.jpg'
// import Image3 from '../images/home-background3.jpg'
// import Image4 from '../images/offimg.jpg'

import {
    StyledPageImageContainer,
    StyledPageDescription,
    StyledPageText,
    StyledPageBackground,

} from '../components/MainStyles/MainContent'

const Offer = ({ path }) => {
    const [state, setState] = useState({
        option: '1'
    })
    const handleOption = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        })
    }
    return (
        <Layout path={path}>
            <SEO
                title="Oferta"
                description="Oferta: Ryczałt i karta podatkowa, KpiR, pełna księgowość."
            />
            <StyledOffer>
                <StyledBoxes variants={fadeAnimation} initial="hidden" animate="show">
                    <StyledBox variants={fadeItem} onClick={handleOption} name="option" value='1' className={state.option === '1' ? "active-option" : ""}>
                        <StyledH2Box>
                            <h2>Ryczałt</h2>
                            <h2>Karta podatkowa</h2>
                        </StyledH2Box>
                        {/* <img src={Image3} alt="" /> */}
                        <StaticImage
                            src='../images/home-background3.jpg'
                            alt="box image"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            placeholder="blurred"
                            loading="eager"
                            layout="constrained"
                            className="box-img"
                        />
                    </StyledBox>
                    <StyledBox variants={fadeItem} onClick={handleOption} name="option" value='2' className={state.option === '2' ? "active-option" : ""}>
                        <h2>KPiR</h2>
                        {/* <img src={Image4} alt="" /> */}
                        <StaticImage
                            src='../images/offimg.jpg'
                            alt="box image"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            placeholder="blurred"
                            loading="eager"
                            layout="constrained"
                            className="box-img"
                        />
                    </StyledBox>
                    <StyledBox variants={fadeItem} onClick={handleOption} name="option" value='3' className={state.option === '3' ? "active-option" : ""}>
                        <h2>Pełna księgowość</h2>
                        {/* <img src={AboutImage} alt="" /> */}
                        <StaticImage
                            src='../images/about.jpg'
                            alt="box image"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            placeholder="blurred"
                            loading="eager"
                            layout="constrained"
                            className="box-img"
                        />
                    </StyledBox>
                </StyledBoxes>
                <StyledContent>
                    <StyledPageDescription>
                        {state.option === '1' &&
                            <StyledPageText variants={fadeAnimation} initial="hidden" animate="show">
                                <motion.h2 variants={fadeItem}>Ryczałt i karta podatkowa</motion.h2>
                                <motion.p variants={fadeItem}>Jest to forma księgowości uproszczonej, na zasadach ryczałtu lub karty
                                podatkowej, która może być używana przez osoby fizyczne prowadzące
                                działalność gospodarczą, przez spółki cywilne osób fizycznych,
                                spółki
                                jawne osób fizycznych, a&nbsp;także spółki partnerskie przy zastrzeżeniu,
                                że
                                przychody netto ze sprzedaży towarów, produktów i&nbsp;operacji finansowych
                                za poprzedni rok obrotowy nie były u&nbsp;nich większe od równowartości 2&nbsp;000&nbsp;000 euro w walucie polskiej.</motion.p>
                                <motion.h4 variants={fadeItem}>Cena:</motion.h4>
                                <motion.h3 variants={fadeItem}>od 100 zł</motion.h3>
                            </StyledPageText>
                        }
                        {state.option === '2' &&
                            <StyledPageText variants={fadeAnimation} initial="hidden" animate="show">
                                <motion.h2 variants={fadeItem}>KPiR</motion.h2>
                                <motion.p variants={fadeItem}>Jest to forma księgowości prowadzona w formie księgi przychodów i&nbsp;
                                rozchodów, która może być używana przez osoby fizyczne prowadzące
                                działalność gospodarczą, przez spółki cywilne osób fizycznych,
                                spółki
                                jawne osób fizycznych, a&nbsp;także spółki partnerskie przy zastrzeżeniu,
                                że
                                przychody netto ze sprzedaży towarów, produktów i&nbsp;operacji finansowych
                                za poprzedni rok obrotowy nie były u&nbsp;nich większe od równowartości 2&nbsp;000&nbsp;000 euro w&nbsp;walucie polskiej.</motion.p>
                                <motion.h4 variants={fadeItem}>Cena:</motion.h4>
                                <motion.h3 variants={fadeItem}>od 150 zł</motion.h3>
                            </StyledPageText>
                        }
                        {state.option === '3' &&
                            <StyledPageText variants={fadeAnimation} initial="hidden" animate="show">
                                <motion.h2 variants={fadeItem}>Pełna księgowość</motion.h2>
                                <motion.p variants={fadeItem}>Prowadzenie ksiąg rachunkowych, m.in. fundacji, stowarzyszeń i&nbsp;spółek z o. o. Opracowanie polityki rachunkowości i&nbsp;sprawozdań finansowych.
                                Pełna księgowość, która określana jest też jako księgi handlowe czy

                                księgi rachunkowe, musi być prowadzona przez firmy, które osiągały
                                przychody za ubiegły rok w&nbsp;wysokości co najmniej 2&nbsp;000&nbsp;000 euro w&nbsp;
                                walucie polskiej, a&nbsp;także przez określone podmioty, w&nbsp;tym spółki handlowe, określone jednostki organizacyjne.</motion.p>
                                <motion.h4 variants={fadeItem}>Cena:</motion.h4>
                                <motion.h3 variants={fadeItem}>od 450 zł</motion.h3>
                            </StyledPageText>
                        }
                    </StyledPageDescription>
                    <StyledPageImageContainer>
                        <StyledPageBackground />
                        {state.option === '1' &&
                            // <StyledPageImage src={Image3} alt="" />
                            <StaticImage
                                src='../images/home-background3.jpg'
                                alt="Optima image"
                                formats={["AUTO", "WEBP", "AVIF"]}
                                placeholder="blurred"
                                loading="eager"
                                layout="constrained"
                                className="page-img"

                            />
                        }
                        {state.option === '2' &&
                            // <StyledPageImage src={Image4} alt="" />
                            <StaticImage
                                src='../images/offimg.jpg'
                                alt="Optima image"
                                formats={["AUTO", "WEBP", "AVIF"]}
                                placeholder="blurred"
                                loading="eager"
                                layout="constrained"
                                className="page-img"

                            />
                        }
                        {state.option === '3' &&
                            // <StyledPageImage src={AboutImage} alt="" />
                            <StaticImage
                                src='../images/about.jpg'
                                alt="Optima image"
                                formats={["AUTO", "WEBP", "AVIF"]}
                                placeholder="blurred"
                                loading="eager"
                                layout="constrained"
                                className="page-img"

                            />
                        }
                    </StyledPageImageContainer>
                </StyledContent>
            </StyledOffer>
        </Layout>
    )
}

const StyledOffer = styled.section`
    min-height: 91vh;
    background: linear-gradient(267.37deg, #ECEBEE 1.05%, rgba(250, 250, 250, 0.06) 122.76%);
    display: flex;
    flex-direction: column;
    justify-content: center;

`

const StyledBoxes = styled(motion.div)`
    margin: 10rem 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    .active-option {
        background: linear-gradient(238.82deg, #4040FF 11.87%, #7723FF 87.59%);
        color: white;
    }
    ${({ theme }) => theme.media.tablet} {
        margin: 10rem 2.5rem 2.5rem 2.5rem;
    }
`
const StyledH2Box = styled.div`
    margin: 2rem 0 1rem 0;
    pointer-events: none;
    ${({ theme }) => theme.media.tablet} {
        margin: 2rem 0;
        text-align: left;
    }
`

const StyledBox = styled(motion.button)`
    transition: all .3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: white;
    box-shadow: 0px 4px 13px #BEBEC9;
    border-radius: 1.6rem;
    height: 33rem;
    width: 31rem;
    margin: 0 4rem;
    h2 {
        font-variant: initial;
        pointer-events: none;
        font-size: 2.8rem;
        font-weight: 600;
        /* margin: 2rem 0 1rem 0; */
        ${({ theme }) => theme.media.phone} {
            margin: 0;
            font-size: 2.8rem;
        }
    }
    .box-img {
        pointer-events: none;
        margin: 2rem;
        width: 13rem;
        height: 13rem;
        object-fit: cover;
        border-radius: 50%;
        box-shadow: 0px 4px 13px #BEBEC9;
        ${({ theme }) => theme.media.tablet} {
            width: 7rem;
            height: 7rem;
            margin: 0 3rem 0 3rem;
        }
    }
    ${({ theme }) => theme.media.tablet} {
        margin: 4rem 6rem;
    }
    ${({ theme }) => theme.media.tablet} {
        justify-content: flex-end;
        flex-direction: row-reverse;
        /* margin: 4rem auto; */
        margin: 1rem auto;
        height: 10rem;
        width: 86vw;
    }
`

const StyledContent = styled.div`
    display: flex;
    padding: 10rem 0 20rem 0rem;
    width: 100%;
    h4 {
        font-weight: 500;
        font-size: 2rem;
        margin: 2rem 0 .5rem 0;
    }
    h3 {
        margin: .5rem 0 2rem 0;
        font-weight: 600;
        color: #433EFF;
        font-size: 4rem;
    }
    ${({ theme }) => theme.media.tablet} {
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding:0rem 0 5rem 0rem;
    }
`

export default Offer
