import styled from 'styled-components'
import { motion } from 'framer-motion'
const StyledMain = styled.section`
    display: flex;
    flex-direction: column;
`

const StyledLine = styled.span`
    background:${({ theme }) => theme.color.white};
    border-radius: 95px;
    height: .7rem;
    width: 80%;
    margin: 15rem auto 5rem auto;
    ${({ theme }) => theme.media.phone} {
        margin:10rem auto 2rem auto;
    }
`

const StyledMainContent = styled.div`
    display: flex;
    ${({ theme }) => theme.media.tablet} {
        align-items: center;
        justify-content: center;
    }
`

const StyledMainDescription = styled.div`
    flex: 1;
    h2 {
        font-size: 4rem;
    }
    p {

        font-size: 2.2rem;
    }
    button {
        background: ${({ theme }) => theme.color.blue};
        border-radius: 4px;
        color: ${({ theme }) => theme.color.white};
        font-size: 2rem;
        padding: 0.6rem 10rem 0.9rem 10rem;
    }
`

const StyledMainText = styled(motion.div)`
    padding: 0 15rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    ${({ theme }) => theme.media.tablet} {
        margin: 5rem 0;
    }
    ${({ theme }) => theme.media.phone} {
        align-items: center;
        margin: 2rem auto;
        padding: 0;
        width: 80vw;
    }
`

const StyledMainBackground = styled.div`
    height: 55rem;
    width: 80%;
    position: absolute;
    ${({ theme }) => theme.media.tablet} {
        display: none;
    }
`

const StyledMainSlider = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
`

const StyledMainCards = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    ${({ theme }) => theme.media.phone} {
        flex-direction: column;
    }
`

const StyledMainCard = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.color.lightGray};
    box-shadow: 0px 4px 74px #D2D0D5;
    border-radius: 16px;
    height: 38rem;
    width: 35rem;
    margin: 0 3rem;
    z-index: 1;

    img {
        width: 30rem;
        height: 36rem;
        /* padding: 5rem 0; */
    }
    ${({ theme }) => theme.media.phone} {
        margin: 3rem 0;
    }
`


//about,offer etc
export const StyledPageImageContainer = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    .page-img {
        margin: 0 15rem;
    width: 40vw;
    height: 46rem;
    object-fit: cover;
    z-index: 2;
    border-radius: 16px;
    box-shadow: 0px 4px 74px #D2D0D5;
    ${({ theme }) => theme.media.tablet} {
        width: 86vw;
        margin: 2rem auto;
    }
    }
`

export const StyledPageBackground = styled(StyledMainBackground)`
    border-radius: 14px 0px 0px 14px;
    background: ${({ theme }) => theme.color.white};
    /* height: 60rem; */
    /* width: 76%; */
    right: 0%;
`

export const StyledPageDescription = styled(StyledMainDescription)`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 15rem;
    p {
        padding: 2rem 0;
    }
    h2 {
        padding-bottom: 2rem;
    }
    ${({ theme }) => theme.media.tablet} {
        margin: 0 15rem;
    }
    ${({ theme }) => theme.media.phone} {
        margin: 0 auto;
        width: 86vw;
    }
`

export const StyledPageText = styled(StyledMainText)`
    padding: 0 !important;
    ${({ theme }) => theme.media.phone} {
        align-items: center;
        text-align: center;
    }
`

export const StyledPageImage = styled.img`
    margin: 0 15rem;
    width: 40vw;
    height: 46rem;
    object-fit: cover;
    z-index: 2;
    border-radius: 16px;
    box-shadow: 0px 4px 74px #D2D0D5;
    ${({ theme }) => theme.media.tablet} {
        width: 86vw;
        margin: 2rem auto;
    }
`
export {
    StyledMain,
    StyledLine,
    StyledMainContent,
    StyledMainDescription,
    StyledMainText,
    StyledMainBackground,
    StyledMainSlider,
    StyledMainCards,
    StyledMainCard
}